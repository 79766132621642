import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DisplayMembers from "../components/DisplayMembers"
import "../components/Dashboard.css"

function Dashboard() {
  return (
    <>
    <h1>Dashboard</h1>
    <Tabs
      defaultActiveKey="home"
      id="justify-tab-example"
      className="dash-tab"
      justify
    >
      <Tab eventKey="home" title="Membership Registration">
        <DisplayMembers/>
        </Tab>
      <Tab eventKey="profile" title="Gallery">
        Gallery
      </Tab>
      <Tab eventKey="longer-tab" title="Mail List">
        mail List
      </Tab>
      <Tab eventKey="contact" title="News Feed">
        News Feed
      </Tab>
    </Tabs>
    </>
  );
}

export default Dashboard;