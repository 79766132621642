import React from 'react';
import '../components/MemberRegistration.css';

function MemberRegistration() {
  return (
    <div className="form-container">
      <form className="registration-form">
        <h2>Membership Registration</h2>
        <label htmlFor="username">Username</label>
        <input id="nam" type="text" placeholder="Username" required />

         <label htmlFor="Email">Email</label>
        <input type="email" placeholder="Email" required />

        <label htmlFor="Password">Password</label>
        <input type="password" placeholder="Password" required />
        <button type="submit">Register</button>
      </form>
    </div>
  );
}

export default MemberRegistration;
