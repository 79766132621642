import Table from 'react-bootstrap/Table';
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../components/DisplayMembers.css"
import imgwhatsapp from "../images/icons/whatsapp.png"
import imgcall from "../images/icons/call.png"

function DisplayMembers() {
    const [data, setData] = useState([]);
    const [counts,SetCounts] = useState(0);

      const updateUserStatus = (userid) => {
        const confirmAction = window.confirm("Confirm Approval?");
        if (confirmAction) { 
        axios.put('https://trial.manjeriwelfare.com/update4524.php', { id: userid })
          .then(response => {
              console.log(response.data);
                })
          .catch(error => console.log(error));
         SetCounts(counts+1)
  };
}
  // Fetch data from the PHP backend
  useEffect(() => {
    axios
      .get("https://trial.manjeriwelfare.com/dbx234.php") // Replace with your actual PHP script URL
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
<>
    <h4 className='bg-primary'>Membership Registration</h4>
    <div id="table-container">
       <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Surname</th>
          <th>Mobile-KSA</th>
          <th>Mobile-India</th>
          <th>Whatsapp Number</th>
          <th>Area-Riyadh</th>
          <th>Area-Manjeri</th>
          <th>Blood Group</th>
          <th>Job</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>

      {data.map(user => (
        <tr>
          <td>{user.name}</td>
          <td>{user.surname}</td>
          <td>{user.phoneksa}<a 
        href={`https://wa.me/+966${user.phoneksa}?text="Hi"`} 
        target="_blank" 
        rel="noopener noreferrer" 
        style={{ textDecoration: 'none', color: 'green' }}
      ><img width="30" height="30" src={imgwhatsapp}/></a>
       <a href={`tel:+966${user.phoneksa}`} style={{ textDecoration: 'none', color: 'blue' }}><img width="30" height="30" src={imgcall}/></a>
      </td>
          <td>{user.phoneindia}</td>
          <td>{user.whatsapp}</td>
          <td>{user.areariyadh}</td>
          <td>{user.areamanjeri}</td>
          <td>{user.bloodgroup}</td>
          <td>{user.job}</td>
          <td>{user.status==="Approved"?user.status:<button onClick={()=>updateUserStatus(user.id)} className='bg-danger color-white'>Approve</button>}</td>

        </tr>
        ))}
              </tbody>
    </Table>
    </div>
    </>
  );
}

export default DisplayMembers;