import {React,useEffect} from 'react'
import "../components/NewsFeed.css";
import onam from '../images/award.jpg'
import retfest from '../images/retfest.jpg'
import happy2025 from '../images/icons/happy2025.png'
import support from '../images/support.jpg'

import AOS from 'aos';
import 'aos/dist/aos.css';

const NewsFeed = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true,     // Animation only happens once
    });
  }, []);
  return (
   <div data-aos="zoom-in">
    <h5 className='bg-secondary'>UPDATES..</h5>
    <div className='div-container'>

<div className='nfeed'>
    <h6></h6>
<div
><h6 className='nhead'></h6>
</div>


<div>
<h6 className='nhead'>അവാർഡ് ദാനം </h6>
    <img className='img-class2' src={onam}></img>

    <h7>മഞ്ചേരി വെൽഫെയർ അസോസിയേഷൻ മെമ്പർമാരുടെ കുട്ടികൾക്കുള്ള പ്ലസ്ടു, എസ് എസ്  എൽ  സി വിജയികൾക്കുള്ള അവാർഡ് ദാനം റിയാദിൽ വെച്ച് നടന്നു.</h7>
</div>
<hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>
<div>

<h6 className='nhead'>ഫാമിലി മീറ്റ്</h6>
<img className='img-class1' src={retfest}></img>

<h7>റിയാദിൽ നിന്ന് നാട്ടിൽ സ്ഥിരതാമസമാക്കിയ മഞ്ചേരി വെൽഫയർ മെമ്പർമാരുടെ ഫാമിലി മീറ്റ് 
  മുള്ളമ്പാറ അഫ്സൽ ഓഡിറ്റോറിയത്തിൽ വെച്ച് സംഘടിപ്പിച്ചു.</h7>

</div>


</div>

<div className='levents'>
  
  <img className='img-eve2' src={happy2025}></img>
  <h6>വയനാടിനൊപ്പം.. </h6>
<img className='img-eve1' src={support}></img>

</div>


<hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>
</div>
    </div>
    
  )
}

export default NewsFeed