import React from 'react'
import Cookies from 'js-cookie';
import { Card, CardGroup, Container, Row, Col, Button } from "react-bootstrap";
import presimg from '../images/committee/salih.jpg'
import secimg from '../images/committee/basheer.jpg'
import treimg from '../images/committee/treasure.jpg'
import patimg from '../images/committee/murali.jpg'
import exe from '../images/committee/exe.jpg'
import '../components/Committee.css';
import ImageGallery from './ImageGallery';
import ImageGrid from './ImageGrid';
import { toHaveFormValues } from '@testing-library/jest-dom/dist/matchers';

const Committee = () => {
  const nam = Cookies.get('myCookie');
  return (
       <div>
       
        <h5 className='bg-info'>EXECUTIVE COMMITTEE</h5>
    <div className='card-div'> 
<CardGroup className='main-card-div'>
            <Card>
        <Card.Img className="img-clas" variant="" src={presimg}/>
        <Card.Body>
          <Card.Title>Salih CK</Card.Title>
          <Card.Text>
            President.{' '}
          </Card.Text>
        </Card.Body>
        
      </Card>
      <Card>
        <Card.Img className="img-clas" variant="" src={secimg}/>
        <Card.Body>
          <Card.Title>Basheer Vallanchira</Card.Title>
          <Card.Text>
            Secretary
          </Card.Text>
        </Card.Body>
       
      </Card>
      <Card>
        <Card.Img className="img-clas" variant="" src={treimg} />
        <Card.Body>
          <Card.Title>Jamshid NT</Card.Title>
          <Card.Text>
            Treasurer
          </Card.Text>
        </Card.Body>
              </Card>
      <Card>
        <Card.Img className="img-clas" variant="" src={patimg} />
        <Card.Body>
          <Card.Title>Muraleedharan DK</Card.Title>
          <Card.Text>
            Chief Patron
          </Card.Text>
        </Card.Body>
       
      </Card>

           
          </CardGroup>
<div>
<h5 className='bg-secondary'>EXECUTIVE MEMBERS</h5>
<ImageGrid/>


</div>

    </div>
    </div>
  )
}

export  default Committee