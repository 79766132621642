import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './Trials.css'; // Add styles for modal

function LoginModal(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    Cookies.set('myCookie', username, { expires: 7 });// Handle login logic (e.g., API request) here
   // alert(`Logging in with Username: ${username}, Password: ${password}`);
    toggleModal(); // Close modal after submission
    alert(props.flag);
    navigate('/');
  };

  return (
    <div>
      <button onClick={toggleModal}>Login</button>

      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>&times;</span>
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Username:</label>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Password:</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit">Login</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginModal;
