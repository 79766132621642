import React from 'react';
import {Card,Button} from 'react-bootstrap';
import '../components/Details.css';
import { Link } from 'react-router-dom';
const reg="Membership Registration>>"
const Details = () => {
  
   return (
    <div>
      <h5 className=''>ORGANIZATION...</h5>
<div className='div-main' >
<Card body className='cardbody'>
<p className='para-details'>
In Riyadh, Saudi Arabia, the Manjeri Expatriate Welfare Association supports expatriates from Manjeri Municipality, Kerala. The organization offers essential services such as legal aid, financial support, and community events, 
helping expatriates adapt and thrive in their new environment while fostering a sense of community and belonging.Manjeri Municipality, located in Kerala, India, is a vibrant administrative and commercial center in Malappuram district. Known for its rich cultural heritage and growing infrastructure, the municipality provides essential services, supports local development, 
and fosters community engagement. It plays a key role in regional growth and urban planning.
</p>
<Link to="/members">

</Link>
    </Card>
    </div>
<p></p>
    </div>
  )

}

export default Details