import React from 'react';
import img1 from '../images/iftarthumb.jpg'
import manjeri from '../video/fest.mp4';
  
  const IftarVideoPlayer = ({ src, width, height }) => {
    return (
        <div className="video-player">
        <video className="iftvideo" width={width} height={height} controls poster={img1}>
          <source src={manjeri} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    )
  }
  
  export default IftarVideoPlayer