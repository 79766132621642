import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Update with your PHP API URL
    axios.get('http://localhost:8080/api.php')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className="bg-danger">
      <h1>User List</h1>
      <ul  className='bg-primary'>
      {users.map(kasp=>(<><h1>{kasp.name}</h1></>))}

        {users.map(user => (
          <><li key={user.id}>{user.name} * {user.email}</li><li key={user.id}>{user.name} * {user.email}</li><br></br></>
        ))}
      </ul>
      <br></br>
      
    </div>
  );
}

export default App;
