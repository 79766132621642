import React, { useState } from "react";
import axios from "axios";
import './MemberRegistrationForm.css'; // Importing CSS
import { useNavigate } from 'react-router-dom';
import mrlogo from "../images/mrlogo.jpg"

const MemberRegistrationForm = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    phoneksa: '',
    phoneindia: '',
    whatsapp: '',
    areariyadh: '',
    areamanjeri: '',
    bloodgroup: '',
    job: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.bloodgroup === "") {
      alert("Please select a blood group.");
      return;
    }

    const confirmAction = window.confirm("Confirm?");
    if (confirmAction) {

    try {
      const response = await axios.post('https://trial.manjeriwelfare.com/registermember.php', formData);
      console.log(response.data);
      
    
      setFormData({
        name: '',
    surname: '',
    phoneksa: '',
    phoneindia: '',
    whatsapp: '',
    areariyadh: '',
    areamanjeri: '',
    bloodgroup: '',
    job: ''
      });
      

      
     navigate('/success');


    } catch (error) {
      console.error("There was an error!", error);
      alert("Registration failed. Please try again.");
    }

  }
  else
   {
   return;
 };

  };
  


  return (
    <div className="main-body">
      
    <h3>Membership Registration</h3>
    <div className="main-body">
    <div className="form-container-2">
      
      <form onSubmit={handleSubmit}>
        
        <div className="form-group">
          <label htmlFor="name">Name(പേര്):</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required />
        </div>
        <div className="form-group">
          <label htmlFor="surname">Surname(വീട്ട് പേര്):</label>
          <input type="text" id="surname" name="surname" value={formData.surname} onChange={handleChange} placeholder="Surname" required />
        </div>
        <div className="form-group">
          <label htmlFor="phoneksa">Mobile Number(KSA)-മൊബൈൽ നമ്പർ-സൗദി അറേബ്യ:</label>
          <input type="text" id="phoneksa" name="phoneksa" value={formData.phoneksa} onChange={handleChange} placeholder="Mobile - KSA" required />
        </div>
        <div className="form-group">
          <label htmlFor="phoneindia">Mobile Number(India)-മൊബൈൽ നമ്പർ-ഇന്ത്യ:</label>
          <input type="text" id="phoneindia" name="phoneindia" value={formData.phoneindia} onChange={handleChange} placeholder="Mobile - India" required />
        </div>
        <div className="form-group">
          <label htmlFor="whatsapp">WhatsApp Number:</label>
          <input type="text" id="whatsapp" name="whatsapp" value={formData.whatsapp} onChange={handleChange} placeholder="Whatsapp Number" required />
        </div>
        <div className="form-group">
          <label htmlFor="areariyadh">Area (Riyadh)-സ്ഥലം-റിയാദിൽ:</label>
          <input type="text" id="areariyadh" name="areariyadh" value={formData.areariyadh} onChange={handleChange} placeholder="Area in Riyadh" required />
        </div>
        <div className="form-group">
          <label htmlFor="areamanjeri">Area (Manjeri)-സ്ഥലം-മഞ്ചേരിയിൽ:</label>
          <input type="text" id="areamanjeri" name="areamanjeri" value={formData.areamanjeri} onChange={handleChange} placeholder="Area in Manjeri" required />
        </div>
        <label id="label-bg" htmlFor="bloodgroup">Blood Group(രക്ത ഗ്രൂപ്പ്):</label>
        <select
        id="bloodgroup"
          name="bloodgroup"
          value={formData.bloodgroup}
          onChange={handleChange}
          required
        >
          <option value="">Select Blood Group..</option>
          <option value="A+">A+</option>
          <option value="A-">A-</option>
          <option value="B+">B+</option>
          <option value="B-">B-</option>
          <option value="AB+">AB+</option>
          <option value="AB-">AB-</option>
          <option value="O+">O+</option>
          <option value="O-">O-</option>
        </select>

        <div className="form-group">
          <label htmlFor="job">Job(ജോലി):</label>
          <input type="text" id="job" name="job" value={formData.job} onChange={handleChange} placeholder="Job" required />
        </div>
        <button id="reg-button" className= "bg-danger w-100" type="submit">Register</button>
      </form>
    </div>
    </div>
    </div>
  );
};

export default MemberRegistrationForm;
