import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 11.1172,
  lng: 76.1199
};

const Map = () => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyCrc5Z-lDdtJyH-7GOQozagRDcsZqB6CwE">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;