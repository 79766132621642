import React from 'react';
import '../components/ImageGrid.css';
import img1 from '../images/committee/aboobacker.jpg'
import img2 from '../images/committee/habeeb.jpg'
import img3 from '../images/committee/haris.jpg'
import img4 from '../images/committee/hassan.jpg'
import img5 from '../images/committee/jafer.jpg'
import img6 from '../images/committee/muhsin.jpg'
import img7 from '../images/committee/muralikrishnan.jpg'
import img8 from '../images/committee/muralinair.jpg'
import img9 from '../images/committee/nazer.jpg'
import img10 from '../images/committee/rafeeqk.jpg'
import img11 from '../images/committee/rafeeqm.jpg'
import img12 from '../images/committee/salam pk.jpg'
import img13 from '../images/committee/shafeeqa.jpg'
import img14 from '../images/committee/shafeeqp.jpg'
import img15 from '../images/committee/shakeel.jpg'
import img16 from '../images/committee/shameer.jpg'
import img17 from '../images/committee/shanavas.jpg'
import img18 from '../images/committee/Vinod.jpg'
import img19 from '../images/committee/zakir.jpg'


const images = [
  { url: img1, title: 'Aboobacker Siddique PT' },
  { url: img2, title: 'Habeeb Rahman' },
  { url: img3, title: 'Haris M ' },
  { url: img4, title: 'Jahfer' },
  { url: img5, title: 'Mohamad Hassan' },
  { url: img6, title: 'Muhsin Thalapil' },
  { url: img7, title: 'Murali Krishnan' },
  { url: img8, title: 'Murali Nair' },
  { url: img9, title: 'Nazer Elankur' },
  { url: img10, title: 'Rafeeque K' },
  { url: img11, title: 'Rafeeque Mecheri' },
  { url: img12, title: 'Salam PK' },
  { url: img13, title: 'Shafeeq Alukkal' },
  { url: img14, title: 'Shafeeq Pulikathodi' },
  { url: img15, title: 'Shakeel Karuthedath' },
  { url: img16, title: 'Shameer Karadan' },
  { url: img17, title: 'Shanavas khan machingal' },
  { url: img18, title: 'Vinod' },
  { url: img19, title: 'Zakir Chalikulangara' }
  ];

const ImageGrid = () => {
  return (
    <div className='div-container-exe' style={styles.container}>
      {images.map((image, index) => (
        <div key={index} style={styles.imageItem}>
          <img className='exe-team-image' src={image.url} alt={image.title} style={styles.image} />
          <div style={styles.title}>{image.title}</div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  imageItem: {
    width: '', // Adjust width for 3 images per row with a small gap
    marginBottom: '20px',
    textAlign: 'center',
      },
  image: {
    width: '150px',
    height: '150px',
        },
    
  title: {
    marginTop: '8px',
    fontSize: '16px',
  }
};

const App = () => {
  return (
    <div style={{ padding: '20px'}}>
      <h1>Image Gallery</h1>
      <ImageGrid />
    </div>
  );
};

export default ImageGrid;