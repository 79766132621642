import React from 'react'
import facebook from "../images/icons/facebook.jpg"

const MyFooter = () => {
  return (
    <footer className="bg-dark text-white py-4">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h5>About Us</h5>
          <p>
            Expatriates Welfare Organization
          </p>
          <a href="https://www.facebook.com/mwa.ryd"><img src={facebook} width={50} height={50}/></a>
        </div>
        <div className="col-md-6 text-md-end">
          <h5>Contact Us</h5>
          <p>Email: info@manjeriwelfare.com</p>
          <p>Phone: +966 54 474 9280</p>
        </div>
      </div>
      <div className="text-center mt-3">
        <p>&copy; 2024 Manjeri Welfare Association. All rights reserved.</p>
         </div>
    </div>
  </footer>
  )
}

export default MyFooter