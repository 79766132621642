import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import "../components/Mycarousel.css";
import img4 from "../images/iftar.jpg";

const Mycarousel = () => {
  return (
    <div className='carousel-div'>
      <div className="sticky-register">
      <Link as={Link} to="/members">
      <span>Registration</span>
      </Link>
    </div>
      <Carousel>
        <Carousel.Item>
        <img className="carousel-img d-block w-100" src="img1.jpg" text="First slide" fluid/>
        <Carousel.Caption>
          <h2></h2>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="carousel-img d-block w-100" src="img2.jpg"  text="Second slide" fluid/>
        <Carousel.Caption>
          <h2></h2>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="carousel-img d-block w-100" src="img3.jpg" text="Third slide" fluid/>
        <Carousel.Caption>
          <h2></h2>
          <p>
                      </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100 carousel-img" src={img4} text="Third slide" />
        <Carousel.Caption>
          <h2></h2>
          <p>
            </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="carousel-img d-block w-100" src="town.jpg" text="First slide" fluid/>
        <Carousel.Caption>
          <h2></h2>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    
    <p></p>
     </div>
     
  )
}

export default Mycarousel