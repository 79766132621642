import React from 'react'
import IftarVideoPlayer from './IftarVideoPlayer'
import './VideoGallery.css';
import fest from '../video/fest.mp4';

const VideoGallery = () => {
  return (
    <div className="App">
      
      <IftarVideoPlayer 
        src={fest} 
        width="640" 
        height="360" 
      />
      
    </div>
  )
}

export default VideoGallery