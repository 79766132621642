import {React,useEffect} from 'react'
import {Card,Button, Container} from 'react-bootstrap';
import '../components/Services.css';
import education from '../images/education.jpg'
import business from '../images/business.jpg'
import healthcare from '../images/healthcare.jpg'
import events from '../images/events.jpg'
import social from '../images/social.jpg'
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Services = () => {
  const details1=" Empower vulnerable communities by providing essential resources, promoting equity, and enhancing overall quality of life.";
  const details2="Celebrate diversity through music, art, and traditions, fostering community spirit and showcasing rich cultural heritage.";
   const details3="Foster learning and skill development, enhancing knowledge, creativity, and personal growth in individuals of all ages and awarding the topers";
   const details4="Activities involve innovation, market research, and strategic planning to create and grow new business ventures effectively.";
  const datas1 = { name: 'Social Welfare', details: details1, folders: 'social'};
  const datas2 = { name: 'Events', details: details2, folders: 'events' };
  const datas3 = { name: 'Education', details: details3, folders: 'education'};
  const datas4 = { name: 'Business', details: details4, folders: 'business'};
  useEffect(() => {
        AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true,     // Animation only happens once
    });
  }, []);
  return (
    <div data-aos="fade-up-left">
       <div className='bg-success'>
      <h5>WE EXPLORE...</h5>
      </div>
        <div className="card-container">
        
        <Card style={{ width: '15rem',height:'25rem' }}>
      <Card.Img className="cardimg" variant="top" src={social} width={90} height={160}/>
      <Card.Body>
        <Card.Title>Social Welfare</Card.Title>
        <Card.Text>
        {details1}
        </Card.Text>
        <Link to="/servicedetails" state={datas1}>
        <Button variant="primary">More..</Button>
        </Link>
        </Card.Body>
    </Card>

        <Card style={{ width: '15rem',height:'25rem' }}>
      <Card.Img className="cardimg" variant="top" src={events} width={90} height={160}/>
      <Card.Body>
        <Card.Title>Events</Card.Title>
        <Card.Text>
        {details2}
        </Card.Text>
        <Link as={Link} to="/servicedetails" state={datas2}>
        <Button variant="primary">More..</Button>
        </Link>
      </Card.Body>
    </Card>

     <Card style={{ width: '15rem',height:'25rem' }}>
      <Card.Img className="cardimg" variant="top" src={education} width={90} height={160} />
      <Card.Body>
        <Card.Title>Education</Card.Title>
        <Card.Text>
        {details3}
              </Card.Text>
              <Link as={Link} to="/servicedetails" state={datas3}>
        <Button variant="primary">More..</Button>
        </Link>
      </Card.Body>
    </Card>


    <Card style={{ width: '15rem',height:'25rem' }}>
      <Card.Img className="cardimg" variant="top" src={business} width={90} height={160}/>
      <Card.Body>
        <Card.Title>Business</Card.Title>
        <Card.Text>
        {details4}
        </Card.Text>
        <Link as={Link} to="/servicedetails" state={datas4}>
        <Button variant="primary">More..</Button>
        </Link>
      </Card.Body>
    </Card>
    </div>
    <hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>
    </div>
  )
}

export default Services