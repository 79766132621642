import React from 'react'
import "../components/Success.css"

const Success = () => {
  return (
    <div className='main-container'>
<div className="container-success">

<h4>Registration Successfull.Our technical team will contact you soon.</h4>

</div>
   </div>
  )
}

export default Success