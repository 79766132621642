import { useEffect } from 'react';
import React from 'react';
import Map from '../components/Map';
import './MyMap.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function MyMap() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true,     // Animation only happens once
    });
  }, []);


  return (
    
    <div data-aos="zoom-in-up" className="App">
      <hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>
      <h4 className=''>Manjeri in Google Map</h4>
      <Map />
      <p></p>

      <hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>

    </div>
  );
}

export default MyMap;