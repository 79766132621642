import React from 'react';
import './Explore.css'; // Import the CSS for the layout
import DisplayImages from './DisplayImages';
import { useLocation } from 'react-router-dom';

const Explore = () => {
  const location = useLocation();
  const datas = location.state || {};
  return (
    <div>
        <h2 className='bg-danger'></h2>
    <div className="page-container">
      <div className="column left-column">
        <h2>{datas.name ? datas.name : 'No name provided'}</h2>
        <p>
          {datas.details}
          
        </p>
      </div>
      <div className="column right-column">
                <DisplayImages folder={datas.folders} progtitle=""/>
      </div>
    </div>
    </div>
  );
};

export default Explore;
